/* ------------------------------- input-group ------------------------------- */
.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 58px;
  padding: 15px 25px;

  border-radius: 8px;
  background: rgba($white, 0.1);
  backdrop-filter: blur(40px);

  @include respond-to("425px") {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
  }

  input[type="email"],
  input[type="text"] {
    padding: 0;

    font-family: "SF Pro Display", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    color: $white;
  }

  input[type="email"]::placeholder,
  input[type="text"]::placeholder {
    font-family: "SF Pro Display", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    color: rgba($white, 0.3);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
  }
}
