// -----------------------------------------------------------------------------
// This file contains styles that are specific to the landing page.
// -----------------------------------------------------------------------------

/* ------------------------------- l-waitlist ------------------------------- */
.l-waitlist {
  .l-waitlist__container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 150px 20px;

    @include respond-to("md") {
      flex-direction: column;
      padding: 100px 20px;
    }
  }

  .l-waitlist__col {
    display: flex;
    flex-direction: column;
    gap: 10px;

    max-width: 615px;
    width: 100%;
  }

  .l-waitlist__img {
    img {
      max-width: 615px;
      width: 100%;
      max-height: 605px;
      height: 100%;
    }
  }
}

/* ------------------------------- l-tile ------------------------------- */
.l-tile {
  .l-tile__container {
    padding: 150px 20px;
  }

  .l-tile__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .l-tile__card {
    max-width: 615px;
    width: 100%;

    img {
      max-width: 615px;
      width: 100%;
    }
  }
}





/*----------------------------------------------------------------------*/




  .l-tile__cards2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

 .l-tile__card2 {
    max-width: 460px;
    width: 100%;

    img {
      max-width: 615px;
      width: 100%;
      height: 420px;
      object-fit: cover;

      @include respond-to("lg") {
     height: 100%;


    }

    }
  }

  .l-tile__container2 {
    padding: 150px 20px;
  }


.card-padding-2{
    padding: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include respond-to("lg") {
    padding: 40px;
    }
}

.card-padding-3{
    padding: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 4px solid rgba(255, 255, 255, 0.05);
    border-top: none;

    @include respond-to("lg") {
    padding: 40px;
    }
}


.video-container {
  position: relative;
  width: 100%;
  max-width: 615px;
  margin: 0 auto;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  max-height: 420px;
  object-fit: cover;
}

#video1 {
  background-image: url("/assets/images/landing/img2-m.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
  @include respond-to('lg') {
    height: 100%;
  }
}

#video2 {
  background-image: url("/assets/images/landing/img3-m.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
  @include respond-to('lg') {
    height: 100%;
  }
}


#wrap {
  position: relative;
}

.section {
  padding: 40px 20px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: 0;
  }

  &--d {
    background: #000;
  }
}

.center {
  width: 100%;
  max-width: 980px;
  margin: auto;
}

#video {
  width: 100%;
  position: relative;

  &-over {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: none;
  }

  &-play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #fff;
    z-index: 1;
    cursor: pointer;
    transition: .2s ease;
    transition-property: opacity, visibility;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 21px;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-width: 12px;
      border-bottom-width: 12px;
      border-left-color: #fff;
      border-right-width: 0;
      transition: transform .2s ease;
    }

    &:hover {
      &::before {
        transform: scale(1.2);
      }
    }
  }

  video {
    width: 100%;
    display: block;
  }

  &.video-is-playing {
    #video {
      &-over {
        display: block;
      }

      &-play {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
