/* ------------------------------- card-big ------------------------------- */
.card-big {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 100px;

  background-color: rgba($white, 0.1);

  transition: all 0.3s ease-in-out;

  @include respond-to("lg") {
    padding: 40px;
  }

  .card-big__icon {
  }

  .card-big__title {
    font-family: "Space Quest", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $white;
  }

  .card-big__text {
  }

  .card-big__link {
    display: flex;
    flex-direction: row;
    gap: 10px;

    font-family: "Space Quest", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;

    color: $yellow;

    @include respond-to("md") {
      line-height: 20px;
    }

    img {
      width: auto !important;
      height: auto !important;
    }
  }

  .card-big__link:hover {
    color: rgba($yellow, 0.8);

    img {
      opacity: 0.8;
    }
  }

  .card-big__link--not-hover {
  }

  .card-big__link--not-hover:hover {
    color: $yellow;

    img {
      opacity: 1;
    }
  }
}

/* ------------------------------- card-middle ------------------------------- */
.card-middle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 50px;

  background-color: rgba($white, 0.1);

  transition: all 0.3s ease-in-out;

  @include respond-to("lg") {
    padding: 40px;
  }

  .card-middle__icon {
  }

  .card-middle__content {
  }

  .card-middle__title {
    font-family: "Space Quest", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $white;

    @include respond-to("md") {
      font-size: 24px;
    }
  }

  .card-middle__subtitle {
    font-family: "Space Quest", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $white;
  }

  .card-middle__text {
    padding-top: 10px;
  }
}

.card-middle--black {
  background-color: rgba($white, 0.05);
}

/* ------------------------------- card-price ------------------------------- */
.card-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 40px;

  background-color: rgba($white, 0.05);

  .card-price__content {
  }

  .card-price__title {
    font-family: "Space Quest", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $white;
  }

  .card-price__description {
    margin-top: 10px;

    color: $grey;
  }

  .card-price__price {
    font-family: "Space Quest", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $yellow;

    span {
      font-size: 24px;
    }
  }

  .card-price__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    list-style-type: none;

    li {
      position: relative;

      padding-left: 34px;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      color: rgba($white, 0.6);

      list-style: none;
    }

    li:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";

      width: 24px;
      height: 24px;

      background-image: url("/assets/images/icons/check-circle-linear.svg");
      background-size: cover;
    }
  }

  .card-price__btn {
  }

  &.not-active {
    background-color: transparent;
    border: 4px solid rgba($white, 0.05);
  }
}

/* ------------------------------- card-row ------------------------------- */
.card-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;
  height: 100%;
  padding: 40px;

  background-color: rgba($white, 0.05);

  @include respond-to("md") {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .card-row__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    max-width: 200px;
    width: 100%;

    @include respond-to("xl") {
      max-width: none;
    }
  }

  .card-row__content {
  }

  .card-row__title {
    font-family: "Space Quest", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $white;
  }

  .card-row__description {
    margin-top: 20px;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

    color: rgba($white, 0.6);
  }

  .card-row__link {
    display: flex;
    flex-direction: row;
    gap: 10px;

    font-family: "Space Quest", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;

    color: $yellow;

    @include respond-to("md") {
      justify-content: space-between;
    }
  }

  .card-row__link:hover {
    color: rgba($yellow, 0.8);

    img {
      opacity: 0.8;
    }
  }

  .card-row__img {
    margin: -40px -40px -40px 0;

    @include respond-to("md") {
      margin: 0;
    }

    img {
      width: 100%;
    }

    &.indent {
      margin-right: -20px;

      @include respond-to("md") {
        margin: 0;
      }

      img {
        width: 100%;
      }
    }
  }
}

/* ------------------------------- card-pay ------------------------------- */

.card-pay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 405px;
  width: 100%;
  height: 100%;
  padding: 40px;

  background: rgba($white, 0.1);

  @include respond-to("md") {
    max-width: initial;
  }

  @include respond-to("390px") {
    padding: 30px;
  }

  .card-pay__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .card-pay__title {
    color: $white;
    font-family: "Space Quest", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .card-pay__user {
  }

  .card-pay__links {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include respond-to("370px") {
      flex-direction: column;
    }
  }

  .card-pay__open {
    display: flex;
    gap: 5px;

    font-size: 18px;

    cursor: pointer;

    transition: all 0.1s ease-in-out;

    &:hover {
      color: rgba($yellow, 0.8);

      svg path {
        opacity: 80%;
      }
    }
  }

  .card-pay__slash {
    @include respond-to("370px") {
      display: none;
    }
  }

  .card-pay__switch {
    font-size: 18px;

    cursor: pointer;

    transition: all 0.1s ease-in-out;

    &:hover {
      color: rgba($yellow, 0.8);
    }
  }

  .card-pay__divider {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;

    border: 1px solid rgba($white, 0.1);
  }

  .card-pay__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }

  .card-pay__text-devider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: rgba($white, 0.5);
    font-family: "Space Quest", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .card-pay__img-disabled {
    opacity: 0.4;
  }

  .card-pay__buy {
  }

  .card-pay__group {
    display: flex;
    gap: 10px;

    @include respond-to("445px") {
      flex-direction: column;
      width: 100%;
    }
  }

  .card-pay__input {
    min-width: 157px;
    width: 100%;

    @include respond-to("445px") {
      min-width: initial;
    }
  }

  .card-pay__summ-to-pay {
    min-width: 157px;
    width: 100%;

    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    border: 2px solid rgba($white, 0.1);
    padding: 15px 25px;
    border-radius: 8px;
    color: $white;

    .card-pay__currency {
      color: $redBright;
    }
  }

  .card-pay__summ {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-pay__btn {
    min-width: 157px;
    width: 100%;
    padding: 15px;
  }

  .card-pay__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    color: $grey;
  }
}

/* ------------------------------- card-table ------------------------------- */

.card-table {
  max-width: 825px;
  width: 100%;
  height: 100%;
  padding: 50px;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  color: $grey;
  background-color: rgba($white, 0.05);

  @include respond-to("xl") {
    max-width: initial;
  }

  @include respond-to("md") {
    padding: 40px;
  }

  @include respond-to("390px") {
    padding: 30px;
  }

  .card-table__title {
    font-family: "Space Quest", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $white;

    @include respond-to("md") {
      padding-bottom: 10px;
    }
  }

  .card-table__table {
    position: relative;
    width: 100%;
  }

  .card-table__tbody {
  }

  .card-table__tr {
    @include respond-to("md") {
      display: flex;
      flex-direction: column;
    }
  }

  .card-table__tr {
    td {
      padding: 20px 10px 0 0;

      @include respond-to("md") {
        padding: 10px 0 0;
      }
    }

    td:last-child {
      padding: 20px 0 0;

      @include respond-to("md") {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}


.card-big2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba($white, 0.05);
  transition: all 0.3s ease-in-out;

}

.card-big2:hover {
  cursor: pointer;
  background-color: rgba($white, 0.10);
  transition: all 0.3s ease-in-out;
}

.card-big3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: none;
  transition: all 0.3s ease-in-out;
}


.card-big__title2 {
  font-family: "Space Quest", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $white;
}


.card-big__link2 {
  display: flex;
  flex-direction: row;
  gap: 10px;

  font-family: "Space Quest", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;

  color: $yellow;

  @include respond-to("md") {
    line-height: 20px;
  }

  img {
    width: auto !important;
    height: auto !important;
  }
}

.card-big__link2:hover {
  color: rgba($yellow, 0.8);

  img {
    opacity: 0.8;
  }
}
