/* ------------------------------- s-pricing ------------------------------- */
.s-pricing {
  .s-pricing__container {
    padding-top: 150px;
    padding-bottom: 150px;

    @include respond-to("sm") {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  .s-pricing__title {
    font-family: "Space Quest", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    color: $white;
  }

  .s-pricing__description {
    margin-top: 5px;
    margin-bottom: 5px;

    text-align: center;
  }

  .s-pricing__plans {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 90px;

    @include respond-to("sm") {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  .s-pricing__plan {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    &.not-active {
      opacity: 40%;
    }
  }

  .s-pricing__text {
    font-family: "Space Quest", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $white;
  }

  .s-pricing__cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-top: 50px;

    @include respond-to("850px") {
      flex-direction: column;
    }

    @include respond-to("sm") {
      flex-direction: row;
      justify-content: flex-start;
      margin: 0 -20px;

      overflow-x: auto;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .s-pricing__card {
    @include respond-to("sm") {
      flex: 0 0 280px;

      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }
    }
  }
}

/* ------------------------------- s-enterprise ------------------------------- */
.s-enterprise {
  .s-enterprise__container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-top: 150px;

    @include respond-to("xl") {
      flex-direction: column;
    }

    @include respond-to("md") {
      padding-top: 100px;
    }
  }

  .s-enterprise__card {
    max-width: 615px;
    width: 100%;

    @include respond-to("xl") {
      max-width: none;
    }
  }
}

/* ------------------------------- s-terms ------------------------------- */

.s-terms {
  .s-terms__container {
    padding-bottom: 40px;

    font-family: "SF Pro Display", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

    color: rgba($white, 0.6);

    h1 {
      margin-bottom: 15px;

      font-family: "Space Quest", sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: $white;
    }

    h2 {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 15px;
    }

    .s-terms__bold {
      font-weight: 700;

      color: $white;
    }

    .s-terms__size-20 {
      font-size: 20px;
    }

    .s-terms__decoration {
      text-decoration: underline;
    }

    a {
      color: $yellow;

      word-wrap: break-word;

      transition: all 0.1s ease-in-out;
    }

    a:hover {
      text-decoration: underline;

      color: rgba($yellow, 0.8);

      transition: all 0.1s ease-in-out;
    }

    ol {
      list-style: none;
      counter-reset: li;
      margin-top: 15px;
    }

    li {
      margin-bottom: 15px;
    }

    ol > li:before {
      counter-increment: li;
      content: counters(li, ".") ". ";
      margin-right: 5px;
    }

    ul {
      list-style: circle;
      padding-top: 15px;
      padding-left: 25px;
    }

    ul > li {
      margin-right: 5px;
    }
  }
}

/* ------------------------------- section profile ------------------------------- */

.s-profile {
  .s-profile__container {
    display: flex;
    gap: 10px;

    padding-top: 100px;
    padding-bottom: 100px;

    @include respond-to("xl") {
      flex-direction: column-reverse;
    }
  }
}
