/* ---------------------------------- Base ---------------------------------- */
/* ---------------------------------- Text ---------------------------------- */
/* --------------------------------- Background -------------------------------- */
/* --------------------------------- Buttons -------------------------------- */
/* ---------------------------------- Cards --------------------------------- */
/* --------------------------------- Inputs --------------------------------- */
/* ---------------------------------- Utils --------------------------------- */
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #0a0909;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

/* ----------------------------- SF Pro Display ----------------------------- */
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.woff");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Semibold.woff");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Bold.woff");
}
/* ----------------------------- Space Quest ----------------------------- */
@font-face {
  font-family: "Space Quest";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Space-Quest/Space-Quest-Regular.ttf");
}
/**
 * Basic typography style for text
 */
body {
  color: rgba(255, 255, 255, 0.6);
  font: normal 100%/1.2 "SF Pro Display", "Arial", sans-serif;
  letter-spacing: normal;
}

.text {
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
}

.text--normal {
  font-weight: 600;
  line-height: normal;
}

.text--grey {
  color: #969696;
}

.text--white {
  color: #ffffff;
}

.text-small {
  font-family: "Space Quest", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffe459;
}

.text-small--red {
  color: #E1090A;
}

.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
@media (max-width: 1120px) {
  .container {
    max-width: 1040px;
  }
}
@media (max-width: 1040px) {
  .container {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 480px;
  }
}

.container2 {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
@media (max-width: 1120px) {
  .container2 {
    max-width: 1040px;
  }
}
@media (max-width: 1040px) {
  .container2 {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .container2 {
    max-width: 480px;
  }
}

/* ------------------------------- btn-waitlist ------------------------------- */
.btn-waitlist {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 165px;
  width: 100%;
  height: 28px;
  padding: 0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #ce331c;
  border: none;
  background: transparent;
  appearance: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.btn-waitlist:hover {
  color: rgba(206, 51, 28, 0.8);
}
.btn-waitlist:hover img {
  opacity: 0.8;
}

.btn-waitlist:disabled {
  pointer-events: none;
}

.btn-waitlist--loader {
  position: relative;
}

/* ------------------------------- btn-secure ------------------------------- */
.btn-secure {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: auto;
  height: 34px;
  padding: 10px 15px;
  font-family: "Space Quest", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000000;
  border-radius: 1000px;
  border: none;
  background: #ffe459;
  box-shadow: 0 0 20px 0 rgba(255, 228, 89, 0.4);
  appearance: none;
  outline: none;
  transition: all 0.2s ease-in-out;
}

/* ------------------------------- btn-price ------------------------------- */
.btn-price {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 58px;
  padding: 15px 40px;
  font-family: "Space Quest", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  border-radius: 8px;
  border: none;
  background-color: #ce331c;
  appearance: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.btn-price.btn-price__paypal {
  background-color: #1C6ECE;
}
.btn-price:disabled {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.05);
  pointer-events: none;
}
@media (max-width: 480px) {
  .btn-price {
    padding: 15px 23px;
  }
}

.btn-price:hover {
  box-shadow: 0 3px 12px #c54936;
  transition: all 0.2s ease-in-out;
}

.btn-price.btn-price__paypal:hover {
  box-shadow: 0 3px 12px #1C6ECE;
  transition: all 0.2s ease-in-out;
}

/* ------------------------------- btn-group ------------------------------- */
.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 38px;
  height: 100%;
}
.btn-group .btn-group__check {
  position: relative;
  width: auto;
}
.btn-group label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
}
.btn-group .btn-group__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  appearance: none;
  cursor: pointer;
}
.btn-group .btn-group__input:checked + .btn-buy {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0);
  transition: all 0.1s ease-in-out;
}

/* ------------------------------- btn-buy ------------------------------- */
.btn-buy {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  padding: 5px 15px;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
  background: transparent;
  border-radius: 24px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  outline: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.btn-buy span {
  text-align: center;
}
.btn-buy:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* ------------------------------- input-group ------------------------------- */
.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 58px;
  padding: 15px 25px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
}
@media (max-width: 425px) {
  .input-group {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
}
.input-group input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
}
.input-group input[type=email],
.input-group input[type=text] {
  padding: 0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
}
.input-group input[type=email]::placeholder,
.input-group input[type=text]::placeholder {
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.3);
}
.input-group input:-webkit-autofill,
.input-group input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.input-group label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
}

/* ------------------------------- card-big ------------------------------- */
.card-big {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1040px) {
  .card-big {
    padding: 40px;
  }
}
.card-big .card-big__title {
  font-family: "Space Quest", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
.card-big .card-big__link {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "Space Quest", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #ffe459;
}
@media (max-width: 768px) {
  .card-big .card-big__link {
    line-height: 20px;
  }
}
.card-big .card-big__link img {
  width: auto !important;
  height: auto !important;
}
.card-big .card-big__link:hover {
  color: rgba(255, 228, 89, 0.8);
}
.card-big .card-big__link:hover img {
  opacity: 0.8;
}
.card-big .card-big__link--not-hover:hover {
  color: #ffe459;
}
.card-big .card-big__link--not-hover:hover img {
  opacity: 1;
}

/* ------------------------------- card-middle ------------------------------- */
.card-middle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1040px) {
  .card-middle {
    padding: 40px;
  }
}
.card-middle .card-middle__title {
  font-family: "Space Quest", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
@media (max-width: 768px) {
  .card-middle .card-middle__title {
    font-size: 24px;
  }
}
.card-middle .card-middle__subtitle {
  font-family: "Space Quest", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
.card-middle .card-middle__text {
  padding-top: 10px;
}

.card-middle--black {
  background-color: rgba(255, 255, 255, 0.05);
}

/* ------------------------------- card-price ------------------------------- */
.card-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.05);
}
.card-price .card-price__title {
  font-family: "Space Quest", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
.card-price .card-price__description {
  margin-top: 10px;
  color: #969696;
}
.card-price .card-price__price {
  font-family: "Space Quest", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffe459;
}
.card-price .card-price__price span {
  font-size: 24px;
}
.card-price .card-price__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  list-style-type: none;
}
.card-price .card-price__list li {
  position: relative;
  padding-left: 34px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  list-style: none;
}
.card-price .card-price__list li:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("/assets/images/icons/check-circle-linear.svg");
  background-size: cover;
}
.card-price.not-active {
  background-color: transparent;
  border: 4px solid rgba(255, 255, 255, 0.05);
}

/* ------------------------------- card-row ------------------------------- */
.card-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;
  height: 100%;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.05);
}
@media (max-width: 768px) {
  .card-row {
    flex-direction: column-reverse;
    gap: 20px;
  }
}
.card-row .card-row__col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  max-width: 200px;
  width: 100%;
}
@media (max-width: 1120px) {
  .card-row .card-row__col {
    max-width: none;
  }
}
.card-row .card-row__title {
  font-family: "Space Quest", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
.card-row .card-row__description {
  margin-top: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
}
.card-row .card-row__link {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "Space Quest", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #ffe459;
}
@media (max-width: 768px) {
  .card-row .card-row__link {
    justify-content: space-between;
  }
}
.card-row .card-row__link:hover {
  color: rgba(255, 228, 89, 0.8);
}
.card-row .card-row__link:hover img {
  opacity: 0.8;
}
.card-row .card-row__img {
  margin: -40px -40px -40px 0;
}
@media (max-width: 768px) {
  .card-row .card-row__img {
    margin: 0;
  }
}
.card-row .card-row__img img {
  width: 100%;
}
.card-row .card-row__img.indent {
  margin-right: -20px;
}
@media (max-width: 768px) {
  .card-row .card-row__img.indent {
    margin: 0;
  }
}
.card-row .card-row__img.indent img {
  width: 100%;
}

/* ------------------------------- card-pay ------------------------------- */
.card-pay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 405px;
  width: 100%;
  height: 100%;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
}
@media (max-width: 768px) {
  .card-pay {
    max-width: initial;
  }
}
@media (max-width: 390px) {
  .card-pay {
    padding: 30px;
  }
}
.card-pay .card-pay__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.card-pay .card-pay__title {
  color: #ffffff;
  font-family: "Space Quest", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-pay .card-pay__links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 370px) {
  .card-pay .card-pay__links {
    flex-direction: column;
  }
}
.card-pay .card-pay__open {
  display: flex;
  gap: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.card-pay .card-pay__open:hover {
  color: rgba(255, 228, 89, 0.8);
}
.card-pay .card-pay__open:hover svg path {
  opacity: 80%;
}
@media (max-width: 370px) {
  .card-pay .card-pay__slash {
    display: none;
  }
}
.card-pay .card-pay__switch {
  font-size: 18px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.card-pay .card-pay__switch:hover {
  color: rgba(255, 228, 89, 0.8);
}
.card-pay .card-pay__divider {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.card-pay .card-pay__main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}
.card-pay .card-pay__text-devider {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Space Quest", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-pay .card-pay__img-disabled {
  opacity: 0.4;
}
.card-pay .card-pay__group {
  display: flex;
  gap: 10px;
}
@media (max-width: 445px) {
  .card-pay .card-pay__group {
    flex-direction: column;
    width: 100%;
  }
}
.card-pay .card-pay__input {
  min-width: 157px;
  width: 100%;
}
@media (max-width: 445px) {
  .card-pay .card-pay__input {
    min-width: initial;
  }
}
.card-pay .card-pay__summ-to-pay {
  min-width: 157px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 15px 25px;
  border-radius: 8px;
  color: #ffffff;
}
.card-pay .card-pay__summ-to-pay .card-pay__currency {
  color: #E1090A;
}
.card-pay .card-pay__summ {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-pay .card-pay__btn {
  min-width: 157px;
  width: 100%;
  padding: 15px;
}
.card-pay .card-pay__text {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #969696;
}

/* ------------------------------- card-table ------------------------------- */
.card-table {
  max-width: 825px;
  width: 100%;
  height: 100%;
  padding: 50px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #969696;
  background-color: rgba(255, 255, 255, 0.05);
}
@media (max-width: 1120px) {
  .card-table {
    max-width: initial;
  }
}
@media (max-width: 768px) {
  .card-table {
    padding: 40px;
  }
}
@media (max-width: 390px) {
  .card-table {
    padding: 30px;
  }
}
.card-table .card-table__title {
  font-family: "Space Quest", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
@media (max-width: 768px) {
  .card-table .card-table__title {
    padding-bottom: 10px;
  }
}
.card-table .card-table__table {
  position: relative;
  width: 100%;
}
@media (max-width: 768px) {
  .card-table .card-table__tr {
    display: flex;
    flex-direction: column;
  }
}
.card-table .card-table__tr td {
  padding: 20px 10px 0 0;
}
@media (max-width: 768px) {
  .card-table .card-table__tr td {
    padding: 10px 0 0;
  }
}
.card-table .card-table__tr td:last-child {
  padding: 20px 0 0;
}
@media (max-width: 768px) {
  .card-table .card-table__tr td:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.card-big2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease-in-out;
}

.card-big2:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-in-out;
}

.card-big3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: none;
  transition: all 0.3s ease-in-out;
}

.card-big__title2 {
  font-family: "Space Quest", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}

.card-big__link2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "Space Quest", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #ffe459;
}
@media (max-width: 768px) {
  .card-big__link2 {
    line-height: 20px;
  }
}
.card-big__link2 img {
  width: auto !important;
  height: auto !important;
}

.card-big__link2:hover {
  color: rgba(255, 228, 89, 0.8);
}
.card-big__link2:hover img {
  opacity: 0.8;
}

/* ------------------------------- pop-up ------------------------------- */
.pop-up {
  height: 100%;
}
.pop-up .pop-up__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}
.pop-up .pop-up__content {
  display: flex;
  align-items: center;
  height: 100%;
}
.pop-up .pop-up__text {
  margin: 0;
  text-align: center;
  color: #0a0909;
}
.pop-up .pop-up__btn {
  display: flex;
  justify-content: flex-end;
}
.pop-up .pop-up__btn button {
  width: auto;
  height: 45px;
}

/* ------------------------------- s-pricing ------------------------------- */
.s-pricing .s-pricing__container {
  padding-top: 150px;
  padding-bottom: 150px;
}
@media (max-width: 480px) {
  .s-pricing .s-pricing__container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.s-pricing .s-pricing__title {
  font-family: "Space Quest", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: #ffffff;
}
.s-pricing .s-pricing__description {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.s-pricing .s-pricing__plans {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 90px;
}
@media (max-width: 480px) {
  .s-pricing .s-pricing__plans {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
.s-pricing .s-pricing__plan {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.s-pricing .s-pricing__plan.not-active {
  opacity: 40%;
}
.s-pricing .s-pricing__text {
  font-family: "Space Quest", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
.s-pricing .s-pricing__cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
}
@media (max-width: 850px) {
  .s-pricing .s-pricing__cards {
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .s-pricing .s-pricing__cards {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 -20px;
    overflow-x: auto;
  }
}
.s-pricing .s-pricing__cards::-webkit-scrollbar-thumb {
  display: none;
}
.s-pricing .s-pricing__cards::-webkit-scrollbar {
  display: none;
}
@media (max-width: 480px) {
  .s-pricing .s-pricing__card {
    flex: 0 0 280px;
  }
  .s-pricing .s-pricing__card:first-child {
    margin-left: 20px;
  }
  .s-pricing .s-pricing__card:last-child {
    margin-right: 20px;
  }
}

/* ------------------------------- s-enterprise ------------------------------- */
.s-enterprise .s-enterprise__container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 150px;
}
@media (max-width: 1120px) {
  .s-enterprise .s-enterprise__container {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .s-enterprise .s-enterprise__container {
    padding-top: 100px;
  }
}
.s-enterprise .s-enterprise__card {
  max-width: 615px;
  width: 100%;
}
@media (max-width: 1120px) {
  .s-enterprise .s-enterprise__card {
    max-width: none;
  }
}

/* ------------------------------- s-terms ------------------------------- */
.s-terms .s-terms__container {
  padding-bottom: 40px;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
}
.s-terms .s-terms__container h1 {
  margin-bottom: 15px;
  font-family: "Space Quest", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
.s-terms .s-terms__container h2 {
  margin-bottom: 15px;
}
.s-terms .s-terms__container p {
  margin-bottom: 15px;
}
.s-terms .s-terms__container .s-terms__bold {
  font-weight: 700;
  color: #ffffff;
}
.s-terms .s-terms__container .s-terms__size-20 {
  font-size: 20px;
}
.s-terms .s-terms__container .s-terms__decoration {
  text-decoration: underline;
}
.s-terms .s-terms__container a {
  color: #ffe459;
  word-wrap: break-word;
  transition: all 0.1s ease-in-out;
}
.s-terms .s-terms__container a:hover {
  text-decoration: underline;
  color: rgba(255, 228, 89, 0.8);
  transition: all 0.1s ease-in-out;
}
.s-terms .s-terms__container ol {
  list-style: none;
  counter-reset: li;
  margin-top: 15px;
}
.s-terms .s-terms__container li {
  margin-bottom: 15px;
}
.s-terms .s-terms__container ol > li:before {
  counter-increment: li;
  content: counters(li, ".") ". ";
  margin-right: 5px;
}
.s-terms .s-terms__container ul {
  list-style: circle;
  padding-top: 15px;
  padding-left: 25px;
}
.s-terms .s-terms__container ul > li {
  margin-right: 5px;
}

/* ------------------------------- section profile ------------------------------- */
.s-profile .s-profile__container {
  display: flex;
  gap: 10px;
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 1120px) {
  .s-profile .s-profile__container {
    flex-direction: column-reverse;
  }
}

/* ------------------------------- waitlist ------------------------------- */
.waitlist {
  background-image: url("/assets/images/other/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.waitlist .waitlist__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 140px 20px;
}
@media (max-width: 768px) {
  .waitlist .waitlist__container {
    padding: 100px 20px;
  }
}
.waitlist .waitlist__container.waiting-list {
  justify-content: center;
  height: 100%;
  padding: 0 20px;
}
@media (max-width: 768px) {
  .waitlist .waitlist__container.waiting-list {
    padding: 0 20px;
  }
}
.waitlist .waitlist__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.waitlist .waitlist__title {
  text-align: center;
  font-family: "Space Quest", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
@media (max-width: 768px) {
  .waitlist .waitlist__title {
    font-size: 32px;
  }
}
.waitlist .waitlist__title--desktop {
  display: block;
}
@media (max-width: 768px) {
  .waitlist .waitlist__title--desktop {
    display: none;
  }
}
.waitlist .waitlist__title--mobile {
  display: none;
}
@media (max-width: 768px) {
  .waitlist .waitlist__title--mobile {
    display: block;
  }
}
.waitlist .waitlist__text {
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #969696;
}
@media (max-width: 768px) {
  .waitlist .waitlist__text {
    text-align: center;
  }
}
.waitlist .waitlist__form {
  position: relative;
  max-width: 560px;
  width: 100%;
}
.waitlist .waitlist__message {
  padding-top: 5px;
  text-align: center;
  color: #ce331c;
}
.waitlist .waitlist__btn {
  width: auto;
}
.waitlist .waitlist__btn-secure {
  position: absolute;
  top: -24px;
  right: -48px;
}
@media (max-width: 625px) {
  .waitlist .waitlist__btn-secure {
    right: -10px;
  }
}
.waitlist.waiting-list {
  height: 100%;
  background-image: url("/assets/images/other/bg-waitlist.png");
}

/* ------------------------------- divider ------------------------------- */
.divider {
  height: 18px;
  background-image: url("/assets/images/other/divider.png");
  background-repeat: no-repeat;
  background-size: cover;
}

/* ------------------------------- loader-waitlist ------------------------------- */
.loader-waitlist {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
@media (max-width: 425px) {
  .loader-waitlist {
    left: 0;
    right: inherit;
  }
}
.loader-waitlist .loader-waitlist__primary:before,
.loader-waitlist .loader-waitlist__primary:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader-waitlist .loader-waitlist__primary:before {
  left: -17px;
}
.loader-waitlist .loader-waitlist__primary:after {
  left: 17px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}
.loader-waitlist .loader-waitlist__primary:before,
.loader-waitlist .loader-waitlist__primary:after,
.loader-waitlist .loader-waitlist__primary {
  width: 9px;
  height: 18px;
  background: #ce331c;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
}
.loader-waitlist .loader-waitlist__primary {
  position: absolute;
  top: 8px;
  right: 45px;
  margin: auto;
  text-indent: -9999em;
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}
@media (max-width: 425px) {
  .loader-waitlist .loader-waitlist__primary {
    right: inherit;
    left: 20px;
  }
}
@-webkit-keyframes loading-keys-app-loading {
  0%, 80%, 100% {
    opacity: 0.75;
    box-shadow: 0 0 #ce331c;
    height: 18px;
  }
  40% {
    opacity: 1;
    box-shadow: 0 -8px #ce331c;
    height: 23px;
  }
}
@keyframes loading-keys-app-loading {
  0%, 80%, 100% {
    opacity: 0.75;
    box-shadow: 0 0 #ce331c;
    height: 18px;
  }
  40% {
    opacity: 1;
    box-shadow: 0 -8px #ce331c;
    height: 23px;
  }
}

.waitlist__title2 {
  text-align: center;
  font-family: "Space Quest", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto;
  color: #ffffff;
}
@media (max-width: 768px) {
  .waitlist__title2 {
    font-size: 32px;
  }
}

/* ------------------------------- c-header ------------------------------- */
.c-header .c-header__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 40px 20px;
}
.c-header .c-header__back {
  cursor: pointer;
}
.c-header .c-header__title {
  font-family: "Space Quest", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}

/* ------------------------------- p-header ------------------------------- */
.p-header {
  background-image: url("/assets/images/other/bg-profile.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.p-header .p-header__container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
/* ------------------------------- l-footer ------------------------------- */
.l-footer .l-footer__container {
  padding: 70px 20px;
}
@media (max-width: 768px) {
  .l-footer .l-footer__container {
    padding: 50px 20px;
  }
}
.l-footer .l-footer__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .l-footer .l-footer__content {
    flex-direction: column;
    gap: 15px;
  }
}
.l-footer .l-footer__info {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.l-footer p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #969696;
}
.l-footer .l-footer__link {
  font-family: "SF Pro Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #969696;
  transition: all 0.1s ease-in-out;
}
.l-footer .l-footer__link:hover {
  color: rgba(255, 228, 89, 0.8);
  transition: all 0.3s ease-in-out;
}

/* ------------------------------- l-waitlist ------------------------------- */
.l-waitlist .l-waitlist__container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 150px 20px;
}
@media (max-width: 768px) {
  .l-waitlist .l-waitlist__container {
    flex-direction: column;
    padding: 100px 20px;
  }
}
.l-waitlist .l-waitlist__col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 615px;
  width: 100%;
}
.l-waitlist .l-waitlist__img img {
  max-width: 615px;
  width: 100%;
  max-height: 605px;
  height: 100%;
}

/* ------------------------------- l-tile ------------------------------- */
.l-tile .l-tile__container {
  padding: 150px 20px;
}
.l-tile .l-tile__cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.l-tile .l-tile__card {
  max-width: 615px;
  width: 100%;
}
.l-tile .l-tile__card img {
  max-width: 615px;
  width: 100%;
}

/*----------------------------------------------------------------------*/
.l-tile__cards2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.l-tile__card2 {
  max-width: 460px;
  width: 100%;
}
.l-tile__card2 img {
  max-width: 615px;
  width: 100%;
  height: 420px;
  object-fit: cover;
}
@media (max-width: 1040px) {
  .l-tile__card2 img {
    height: 100%;
  }
}

.l-tile__container2 {
  padding: 150px 20px;
}

.card-padding-2 {
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 1040px) {
  .card-padding-2 {
    padding: 40px;
  }
}

.card-padding-3 {
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 4px solid rgba(255, 255, 255, 0.05);
  border-top: none;
}
@media (max-width: 1040px) {
  .card-padding-3 {
    padding: 40px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 615px;
  margin: 0 auto;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  max-height: 420px;
  object-fit: cover;
}

#video1 {
  background-image: url("/assets/images/landing/img2-m.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
}
@media (max-width: 1040px) {
  #video1 {
    height: 100%;
  }
}

#video2 {
  background-image: url("/assets/images/landing/img3-m.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
}
@media (max-width: 1040px) {
  #video2 {
    height: 100%;
  }
}

#wrap {
  position: relative;
}

.section {
  padding: 40px 20px;
  border-bottom: 1px solid #ddd;
}
.section:last-child {
  border-bottom: 0;
}
.section--d {
  background: #000;
}

.center {
  width: 100%;
  max-width: 980px;
  margin: auto;
}

#video {
  width: 100%;
  position: relative;
}
#video-over {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
}
#video-play {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff;
  z-index: 1;
  cursor: pointer;
  transition: 0.2s ease;
  transition-property: opacity, visibility;
}
#video-play::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 21px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-color: #fff;
  border-right-width: 0;
  transition: transform 0.2s ease;
}
#video-play:hover::before {
  transform: scale(1.2);
}
#video video {
  width: 100%;
  display: block;
}
#video.video-is-playing #video-over {
  display: block;
}
#video.video-is-playing #video-play {
  opacity: 0;
  visibility: hidden;
}