// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

/* ------------------------------- btn-waitlist ------------------------------- */
.btn-waitlist {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 165px;
  width: 100%;
  height: 28px;
  padding: 0;

  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  color: $red;
  border: none;
  background: transparent;
  appearance: none;
  outline: none;

  transition: all 0.2s ease-in-out;

  cursor: pointer;
}

.btn-waitlist:hover {
  color: rgba($red, 0.8);

  img {
    opacity: 0.8;
  }
}

.btn-waitlist:disabled {
  pointer-events: none;
}

.btn-waitlist--loader {
  position: relative;
}

/* ------------------------------- btn-secure ------------------------------- */
.btn-secure {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: auto;
  height: 34px;
  padding: 10px 15px;

  font-family: "Space Quest", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: $black;
  border-radius: 1000px;
  border: none;
  background: $yellow;
  box-shadow: 0 0 20px 0 rgba($yellow, 0.4);
  appearance: none;
  outline: none;

  transition: all 0.2s ease-in-out;

  //cursor: pointer;
}

/* ------------------------------- btn-price ------------------------------- */

.btn-price {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 58px;
  padding: 15px 40px;

  font-family: "Space Quest", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: $white;
  border-radius: 8px;
  border: none;
  background-color: $red;
  appearance: none;
  outline: none;

  transition: all 0.2s ease-in-out;

  cursor: pointer;

  &.btn-price__paypal {
    background-color: $blue;
  }

  &:disabled {
    color: rgba($white, 0.4);
    background-color: rgba($white, 0.05);

    pointer-events: none;
  }

  @include respond-to("sm") {
    padding: 15px 23px;
  }
}

.btn-price:hover {
  box-shadow: 0 3px 12px #c54936;

  transition: all 0.2s ease-in-out;
}

.btn-price.btn-price__paypal:hover {
  box-shadow: 0 3px 12px $blue;

  transition: all 0.2s ease-in-out;
}

/* ------------------------------- btn-group ------------------------------- */

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 38px;
  height: 100%;

  .btn-group__check {
    position: relative;

    width: auto;
  }

  label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
  }

  .btn-group__input {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    appearance: none;
    cursor: pointer;
  }

  .btn-group__input:checked + .btn-buy {
    background: rgba($white, 0.1);
    border: 2px solid rgba($white, 0);

    transition: all 0.1s ease-in-out;
  }
}

/* ------------------------------- btn-buy ------------------------------- */

.btn-buy {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  padding: 5px 15px;

  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  color: $white;
  background: transparent;
  border-radius: 24px;
  border: 2px solid rgba($white, 0.1);
  outline: none;
  cursor: pointer;

  transition: all 0.1s ease-in-out;

  span {
    text-align: center;
  }

  &:hover {
    background: rgba($white, 0.1);
  }
}
